import React from "react";

import UniverseNavbar from "../UniverseNavbar";
import {
    UniverseContainer,
    UniverseHeadbar,
    UniverseHeadLeft,
    UniverseHeadRight, UniverseWrapper
} from "../UniverseHomepage/UniverseElements";
import SwitchDark from "../SwitchDark";
import {
    ArticleContainer, ArticleDate, ArticleFooter,
    ArticleH1,
    ArticleH2,
    ArticleImg,
    ArticleP,
    ArticleWrapper
} from "../UniverseAnoryss/UniverseAnoryssElements";

const UniverseAnoryss = () => {
    // const isDark = 1;

    return(
        <>
            <UniverseNavbar></UniverseNavbar>
            <UniverseContainer id="UniverseContainer">
                <UniverseHeadbar>
                    <UniverseHeadLeft>Universe BETA</UniverseHeadLeft>
                    <UniverseHeadRight>
                        {/*<SearchIcon className={"searchIconUniverse"}></SearchIcon>
                        <input className={"searchBarUniverse"} type={"text"} placeholder={"search"} />*/}
                        <SwitchDark />
                    </UniverseHeadRight>
                </UniverseHeadbar>
                <UniverseWrapper>

                    <ArticleContainer>
                        <ArticleWrapper>
                            <ArticleH1>
                                Anoryss
                            </ArticleH1>
                            <ArticleH2>

                            </ArticleH2>
                            <ArticleP>
                                Anoryss risked his life for the redemption of his own race,
                                having no idea that his brave doing would have founded one of
                                the most powerful dynasties, ruler of the 5 galaxies of fire.
                            </ArticleP>
                            <ArticleImg></ArticleImg>

                            <ArticleDate>Neeve Chronicles - 05/07/2022</ArticleDate>
                        </ArticleWrapper>
                        <ArticleFooter>

                        </ArticleFooter>
                    </ArticleContainer>
                </UniverseWrapper>
            </UniverseContainer>
        </>
    )
}

export default UniverseAnoryss