import React from "react";
import {ArticleWrapper,ArticleH1,ArticleContainer,ArticleH2,ArticleImg,ArticleP,ArticleDate,ArticleFooter} from './GauwillElements.js';
import {
    // UniverseArticleCard, UniverseArticleCardBack, UniverseArticleCardData, UniverseArticleCardH1, UniverseArticleCardH2,
    // UniverseArticlesContainer,
    UniverseContainer,
    // UniverseH2,
    UniverseHeadbar,
    UniverseHeadLeft,
    UniverseHeadRight,
    UniverseWrapper
} from "../UniverseHomepage/UniverseElements";
import UniverseNavbar from "../UniverseNavbar";
import "../UniverseHomepage/universehomepage.css";
import SwitchDark from "../SwitchDark";
/*import Data from './Data';*/

const UniverseGauwill = () => {
    // const isDark = 1;

    return(
        <>
            <UniverseNavbar></UniverseNavbar>
            <UniverseContainer id="UniverseContainer">
                <UniverseHeadbar>
                    <UniverseHeadLeft>Universe BETA</UniverseHeadLeft>
                    <UniverseHeadRight>
                        {/*<SearchIcon className={"searchIconUniverse"}></SearchIcon>
                        <input className={"searchBarUniverse"} type={"text"} placeholder={"search"} />*/}
                        <SwitchDark />
                    </UniverseHeadRight>
                </UniverseHeadbar>
                <UniverseWrapper>

                    <ArticleContainer>
                        <ArticleWrapper>
                            <ArticleH1>
                                Gauwill
                            </ArticleH1>
                            <ArticleH2>
                                Hemoth Empire's Bulwark
                            </ArticleH2>
                            <ArticleP>
                                Gauwill, the biggest planet of the five Fire Galaxies,
                                was produced by debris and star clusters in the fourth galaxy.
                                A rocky region rich in resources to craft weapons
                                and unbreakable armor.
                            </ArticleP>
                            <ArticleP>
                                Gauwill had long been a dream of every regnant, as it was one
                                of the most wanted worlds for cosmic explorers.
                                Only the Hemoth Empire was successful in settling on the
                                rich grounds of this wonderful globe, resulting in a
                                population of 5 billion people of all races.
                            </ArticleP>
                            <ArticleImg></ArticleImg>

                            <ArticleDate>Neeve Chronicles - 21/06/2022</ArticleDate>
                        </ArticleWrapper>
                        <ArticleFooter>

                        </ArticleFooter>
                    </ArticleContainer>
                </UniverseWrapper>
            </UniverseContainer>
        </>
    )
}

export default UniverseGauwill