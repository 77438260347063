import styled from "styled-components";
import darkicon from "../../images/night-mode-white.png";
import lighticon from "../../images/sun-white.png";

export const SwitchDarkContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 8px;
    `

export const IconStatus = styled.div`
    background-image: url(${darkicon});
    background-size: contain;
    height: 28px;
    width: 28px;
    margin-left: 8px;
    
    &.light{
        background-image: url(${lighticon});
    }
    `