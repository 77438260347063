import React from "react";
import {LinkDispatcherContainer, LinkDispatcherWrapper, LinkElement,LinkWrapper,LinkText} from "./LinkdispatcherElements";

const LinkDispatcher = () => {

    return(
        <LinkDispatcherContainer>
            <LinkDispatcherWrapper>
                <LinkElement>
                    <LinkWrapper>
                        <LinkText>First Link</LinkText>
                    </LinkWrapper>
                </LinkElement>
            </LinkDispatcherWrapper>
        </LinkDispatcherContainer>
    )

}

export default LinkDispatcher;