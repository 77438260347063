import React from "react";
// import {NavLink, Router} from "react-router-dom";
import {
    ButtonContainer, ButtonImage, ButtonText, LinkUniverse
} from "./UniverseButtonElements";
import "./universebutton.css";

const UniverseButton = () => {

    return(
        <ButtonContainer class="btnUniCont">
            <LinkUniverse to="/Universe" />
            <ButtonImage class="btnUniImg" />
            <ButtonText class="btnUniText">
                Universe
            </ButtonText>
        </ButtonContainer>
    )
}

export default UniverseButton;