import React from "react";
import {UniverseNavbarContainer, UniverseNavbarWrapper, NavMenuLeft, NavMenuRight,NavIcon} from "./UniverseNavbarElements";
// import SwitchDark from "../SwitchDark";

const UniverseNavbar = () => {

    return(
        <>
            <UniverseNavbarContainer>
                <UniverseNavbarWrapper>
                    <NavMenuLeft to="/" onclick="AOS.init();">HOME</NavMenuLeft>
                    <NavMenuRight to="/Universe">
                        <NavIcon></NavIcon>
                        UNIVERSE
                    </NavMenuRight>
                </UniverseNavbarWrapper>
            </UniverseNavbarContainer>
        </>
    )
};

export default UniverseNavbar;