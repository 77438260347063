import React from "react";
import {
    UniverseHeadbar,
    UniverseHeadLeft,
    UniverseHeadRight,
    UniverseWrapper,
    UniverseH2,
    UniverseContainer,
    UniverseArticlesContainer,UniverseCaption,
    UniverseArticleCard,UniverseArticleLink,UniverseArticleLock,UniverseArticleCardImg,
    UniverseArticleCardData,UniverseArticleCardH1,UniverseArticleCardBack
} from "./UniverseElements";
import "./universehomepage.css";
// import UniverseArticle from "../UniverseArticle";
import Galaxy from "../../images/galaxy-low.jpg";
import Gauwill from "../../images/gauwill-low.jpg";
import Xeldris from "../../images/XeldrisLow.jpg";
import Anoryss from "../../images/AnoryssLow.jpg";
import SwitchDark from "../SwitchDark";
// import SwitchLang from "../SwitchLang";

const UniverseHomepage = () => {

    return(

            <UniverseContainer id="UniverseContainer" >
                <UniverseHeadbar>
                    <UniverseHeadLeft>Universe BETA</UniverseHeadLeft>
                    <UniverseHeadRight>
                        {/*<SearchIcon className={"searchIconUniverse"}></SearchIcon>
                        <input className={"searchBarUniverse"} type={"text"} placeholder={"search"} />*/}
                        <SwitchDark/>
                    </UniverseHeadRight>
                </UniverseHeadbar>
                <UniverseWrapper>

                    <UniverseH2>Suggested Articles</UniverseH2>
                    <UniverseArticlesContainer>
                        <UniverseArticleCard>
                            <UniverseArticleCardBack>
                                <UniverseArticleCardImg style={{backgroundImage: `url(${Galaxy})`}}></UniverseArticleCardImg>
                            </UniverseArticleCardBack>
                            <UniverseArticleLink to="/Universe/Galaxy">
                                <UniverseArticleCardH1>
                                    The Galaxies of Fire
                                </UniverseArticleCardH1>
                                {/*<UniverseArticleCardH2>Prequel</UniverseArticleCardH2>*/}
                                <UniverseArticleCardData>14/06/2022</UniverseArticleCardData>
                            </UniverseArticleLink>

                        </UniverseArticleCard>
                        <UniverseArticleCard>
                            <UniverseArticleCardBack>
                                <UniverseArticleCardImg style={{backgroundImage: `url(${Gauwill})`}}></UniverseArticleCardImg>
                            </UniverseArticleCardBack>
                            <UniverseArticleLink to="/Universe/Gauwill">
                                <UniverseArticleCardH1>Gauwill</UniverseArticleCardH1>
                                {/*<UniverseArticleCardH2></UniverseArticleCardH2>*/}
                                <UniverseArticleCardData>21/06/2022</UniverseArticleCardData>
                            </UniverseArticleLink>
                        </UniverseArticleCard>
                        <UniverseArticleCard>
                            <UniverseArticleCardBack>
                                <UniverseArticleCardImg style={{backgroundImage: `url(${Xeldris})`}}></UniverseArticleCardImg>
                            </UniverseArticleCardBack>
                            <UniverseArticleLink to="/Universe/Xeldris">
                                <UniverseArticleCardH1>Xeldris</UniverseArticleCardH1>
                                {/*<UniverseArticleCardH2></UniverseArticleCardH2>*/}
                                <UniverseArticleCardData>28/06/2022</UniverseArticleCardData>
                            </UniverseArticleLink>
                        </UniverseArticleCard>
                        <UniverseArticleCard>
                            <UniverseArticleCardBack>
                                <UniverseArticleCardImg style={{backgroundImage: `url(${Anoryss})`}}></UniverseArticleCardImg>
                            </UniverseArticleCardBack>
                            <UniverseArticleLink to="/Universe/Anoryss">
                                <UniverseArticleCardH1>Anoryss</UniverseArticleCardH1>
                                {/*<UniverseArticleCardH2></UniverseArticleCardH2>*/}
                                <UniverseArticleCardData>05/07/2022</UniverseArticleCardData>
                            </UniverseArticleLink>
                        </UniverseArticleCard>
                        <UniverseArticleCard>
                            <UniverseArticleCardBack>
                                <UniverseArticleLock/>
                                <UniverseArticleCardImg style={{backgroundColor:`#0c0c0c`}}></UniverseArticleCardImg>
                                <UniverseCaption>
                                    <UniverseArticleCardH1>Coming Soon</UniverseArticleCardH1>
                                    {/*<UniverseArticleCardH2></UniverseArticleCardH2>*/}
                                    <UniverseArticleCardData>July 2022</UniverseArticleCardData>
                                </UniverseCaption>
                                </UniverseArticleCardBack>
                        </UniverseArticleCard>
                        <UniverseArticleCard>
                            <UniverseArticleCardBack>
                                <UniverseArticleLock/>
                                <UniverseArticleCardImg style={{backgroundColor:`#0c0c0c`}}></UniverseArticleCardImg>
                                <UniverseCaption>
                                    <UniverseArticleCardH1>Coming Soon</UniverseArticleCardH1>
                                    {/*<UniverseArticleCardH2></UniverseArticleCardH2>*/}
                                    <UniverseArticleCardData>July 2022</UniverseArticleCardData>
                                </UniverseCaption>
                            </UniverseArticleCardBack>
                        </UniverseArticleCard>
                    </UniverseArticlesContainer>
                    {/*<UniverseH2>Latest Articles</UniverseH2>*/}
                    {/*<UniverseArticlesContainer>*/}
                    {/*    <UniverseArticleCard></UniverseArticleCard>*/}
                    {/*</UniverseArticlesContainer>*/}
                </UniverseWrapper>
            </UniverseContainer>

    )
};

export default UniverseHomepage;