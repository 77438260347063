import styled from "styled-components";
// import portal from "../../images/portal.jpg";
// import portallow from "../../images/portal-low.jpg";

export const NftContainer = styled.div`
    display: flex;
    
    // min-height: 400px;
    
    flex-align: center;
    flex-direction: column;
    
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    // height: 75vh;
    /*background: linear-gradient(135deg, #0c0414, #101214);*/
    padding-top: 48px;
    align-items: center;
    
`

export const NftWrapper = styled.div`
    padding-top: 60px;
    display: flex;
    min-height: 800px;
    // height: 100%;
    flex-direction: column;
    width: 1000px;
    max-width: 100%;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    
    @media screen and (max-width: 768px){
        padding: 60px 32px;
    }
    
    @media screen end (max-width: 480px){
    }
`

export const NftFilter = styled.div`
    background-color: rgba(0,0,0,0.33);
    width:100%;
    height: calc(100% + 48px);
    margin-top: -48px;
    display: flex;
    align-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
`

export const NftHeader = styled.div`
    display: flex;
    flex-direction: column;    
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    width: 100%;
}
    
`

export const NftTextContainer = styled.div`
    display: flex;
    // padding: 60px;
    padding: 28px;
    flex-direction: column;
    
`

export const NftTokensContainer = styled.div`
    display: flex;
    
`

export const NftCardWrapper = styled.div`
    display: flex;
    
`

export const NftCard = styled.div`
    display: flex;
    height: 240px;
    width: 100px;
    clip-path: polygon(50% 0, 100% 20%, 100% 80%, 50% 100%, 0 80%, 0 20%);
    background-color: white;
    
`

export const NftCardTop = styled.div`
    display: flex;
    background: red;
    -webkit-clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
    clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
    
`

export const NftCardBot = styled.div`
    display: flex;
    background: blue;
    -webkit-clip-path: polygon(50% 0%, 95% 25%, 95% 75%, 50% 100%, 5% 75%, 5% 25%);
    clip-path: polygon(50% 0%, 95% 25%, 95% 75%, 50% 100%, 5% 75%, 5% 25%);
    
`

export const NftH1 = styled.h1`
    font-family: 'Nunito Sans',sans-serif;
    font-weight: 800;
    font-size: 108px;
    line-height: 96px;
    text-align: center;
    text-transform: uppercase;
    color: white;
    letter-spacing: 0.1rem;
    
    width: 1000px;
    max-width: 95%;
    text-shadow: 2px 2px 2px rgb(0 0 0 / 50%);
    
  // -webkit-text-fill-color: transparent;
  // -webkit-text-stroke-width: 2px;
  // -webkit-text-stroke-color: white;
  
  @media screen and (max-width: 1000px){
        font-size: 90px;
        line-height: 86px;
  }
  
    @media screen and (max-width: 768px){
        font-size: 64px;
        line-height: 56px;
    }
    
    @media screen and (max-width: 600px){  
        font-size: 48px;
        line-height: 42px;
    }
    
    @media screen end (max-width: 480px){

        font-size: 48px;
        line-height: 42px;

    }
`

export const NftH2 = styled.h2`
    font-family: 'Montserrat',sans-serif;
    font-weight: 'SemiBold';
    // font-size: 40px;
    
    font-size: 60px;
    line-height: 56px;
    
    line-height: auto;
    letter-spacing: 10%;
    text-align: center;
    text-transform: uppercase;
    color: white;
    margin: 24px 0;
    
    @media screen and (max-width: 768px){
        font-size: 42px;
        line-height: 38px;
    }
    
    @media  screen and (max-width: 600px){
        font-size: 36px;
        line-height: 30px;
    }
    
    @media screen end (max-width: 480px){
        font-size: 24px;
        line-height: 20px;
    }
`

export const NftH3 = styled.h3`
    font-family: 'Montserrat',sans-serif;
    font-weight: 'SemiBold';
    font-size: 40px;
    line-height: auto;
    letter-spacing: 10%;
    text-align: center;
    text-transform: uppercase;
    color: white;
`


export const NftP = styled.p`
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 1.2px;
    font-family: 'Montserrat',sans-serif;
    text-align: center;
    font-weight: 600;
    color: white;
    text-transform: uppercase;
    text-shadow: 2px 0px 4px rgba(0 0 0 / 50%);
    
    
    @media screen and (max-width: 600px){
        font-size: 16px;
        line-height: 20px;
    }
    
`