import React, {useState,useEffect} from "react";
import {
    HeroContainer,
    LineSeparation,
    TriangleDown,HeroParallaxDimensions,
    HeroBtnWrapper,
    HeroLogo,
    HeroContent,
    HeroH1
} from "./HeroElements";
/*import Video from '../../videos/video.mp4'*/
// import {Button} from "../ButtonElement";
// import DiscordButton from "../DiscordButton";
import UniverseButton from "../UniverseButton";
import svgLineSeparation from "../../images/LineSeparationHero.svg";
import {Parallax, Background} from "react-parallax";
import BGHero from '../../images/GAUWILLDEF.jpg';
import BGHeroMobile from '../../images/gauwill-medium.jpg';
import "../HeroSection/herosection.css";

const HeroSection = () => {
    // const [hover, setHover] = useState(false);
    //
    // const onHover = () => {
    //     setHover(!hover)
    // }

    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    let imghero = width > 768 ? BGHero : BGHeroMobile;

    return (

        <HeroContainer id="home">
                <HeroContent>
                        <Parallax strength={300} bgImageAlt="hero" >
                            <Background>
                                <img src={imghero} alt="hero"/>
                            </Background>
                            <HeroParallaxDimensions>
                                <HeroLogo/>
                                <HeroH1>A new story adventure is coming</HeroH1>
                                {/*<HeroP>*/}
                                {/*    An Adventure begins*/}
                                {/*</HeroP>*/}
                                <HeroBtnWrapper>
                                    <UniverseButton/>
                                    {/*<Button to="signup" onMouseEnter={onHover} onMouseLeave={onHover} primary="true" dark="true" smooth={true} duration={500} spy={true}
                            offset={-80} >
                        Read more {hover ? <ArrowForward /> : <ArrowRight />}
                    </Button>*/}
                                </HeroBtnWrapper>
                                {/*<ArrowsDownContainer/>*/}
                            </HeroParallaxDimensions>
                        </Parallax>
                </HeroContent>
                <LineSeparation>
                    <TriangleDown/>
                    <img src={svgLineSeparation} alt={"spacer"}/>
                </LineSeparation>

            {/*<HeroBg>*/}
            {/*    /!*<VideoBg autoPlay loop muted src={Video} type="video/mp4" />*!/*/}
            {/*</HeroBg>*/}

        </HeroContainer>
    )
}

export default HeroSection;