import {React} from "react";
import ScrollToTop from "../components/ScrollToTop";
import UniverseHomepage from "../components/UniverseHomepage";
import UniverseNavbar from "../components/UniverseNavbar";
// import $ from 'jquery';
import {Route, Routes} from "react-router-dom";
// import Home from "./index";
// import LinkDispatcher from "../components/LinkDispatcher";
import UniverseArticle from "../components/UniverseArticle";

const Universe = () => {

    // console.log(document.getElementById("switchDarkButton"));

    // const afterClick = () => {
    //     console.log("test")
    //     $("#UniverseContainer").toggleClass("Dark");
    //     $("#UniverseContainer").toggleClass("Light");
    // }

    // function toggleDark() {
    //     afterClick();
    // }

    // document.getElementById("switchDarkButton").addEventListener("click",afterClick)

    return(
        <>
            <ScrollToTop />
            <UniverseNavbar></UniverseNavbar>
                <Routes>
                    <Route path="/Universe" element={<UniverseHomepage />} />
                    <Route path="/Universe/Galaxy"  element={<UniverseArticle/>} />
                </Routes>
            <UniverseHomepage/>
        </>
    )
};

export default Universe;