import './App.css';
import Home from "./pages";
import {BrowserRouter as Router} from 'react-router-dom';
import {Route, Routes} from 'react-router-dom';
import Universe from "./pages/universe";
import LinkDispatcher from "./components/LinkDispatcher";
import React, {useState, useEffect} from "react";
import UniverseArticle from "./components/UniverseArticle";
import UniverseGauwill from "./components/UniverseGauwill";
import UniverseAnoryss from "./components/UniverseAnoryss";
import UniverseXeldris from "./components/UniverseXeldris";
// import $ from 'jquery';

const App = () => {

    // componentDidMount(){
    //         $("#loader").addClass("ready");
    // }


    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

      return(
        <Router>
            <Routes>
                <Route path="/" element={<Home data={width}/>}/>
                <Route path="/Universe" element={<Universe/>}/>
                <Route path="/Links" element={<LinkDispatcher/>}/>
                <Route path="/Universe/Galaxy" element={<UniverseArticle/>}/>
                <Route path="/Universe/Gauwill" element={<UniverseGauwill/>}/>
                <Route path="/Universe/Xeldris" element={<UniverseXeldris/>}/>
                <Route path="/Universe/Anoryss" element={<UniverseAnoryss/>}/>
            </Routes>
        </Router>
      );
}

export default App;
