import styled from "styled-components";
import Galaxy from "../../images/GALAXY.jpg";

export const ArticleContainer = styled.div`
    max-width: 1100px;
    display: flex;
    flex-direction: column;
    margin: -12px;
`
export const ArticleWrapper = styled.div`
    padding: 0 12px;
    display: flex;
    flex-direction: column;
}
`
export const ArticleH1 = styled.h1`
    /*padding-left: 48px;*/
    font-size: 48px;
    line-height: 48px;
    text-transform: uppercase;
    font-family: montserrat;
    letter-spacing: 0.1em;
    color: inherit !important;
`

export const ArticleH2 = styled.h2`
    /*padding-left: 48px;*/
    font-family: montserrat;
    color: inherit !important;
    margin-bottom: 12px;
`

export const ArticleP = styled.p`
    font-family: lato;
    font-size: 20px;
    line-height: 24px;
    margin-top: 12px;
    color: inherit !important;
`

export const ArticleImg = styled.div`
    background-image: url(${Galaxy});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    /*width: 100%;*/
    width: 100%;
    height: 0;
    margin: 24px 0;
    padding-top: 53.4%;
`

export const ArticleFooter = styled.div`
    
`

export const ArticleDate = styled.div`
    text-align: right;
    // margin-top: -16px;
    font-size: 12px;
    font-weight: bold;
    font-family: montserrat;
    color: inherit !important;
    
        @media screen and (max-width: 768px){
            margin-bottom: 32px;
    }
`