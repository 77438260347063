import React from "react";
import Image from "../../images/galaxy-medium.jpg";
import spacer from "../../images/spacer-universe.svg"
import UniverseButton from "../UniverseButton";

import {UniverseWrapper,UniverseSpacer,UniverseSpacerEnd,UniverseImage1,UniverseH2,UniverseElH1,UniverseElP,UniverseH1,UniverseElement,UniverseContainer,UniverseB1,UniverseThumbnail,UniverseImageWrapper} from "./UniversepreviewElements";
import {Parallax} from "react-parallax";
// import BGHero from "../../images/GAUWILLDEF.jpg";

const UniversePreview = () => {
    return(
        <UniverseContainer id="universe">
            <UniverseSpacer>
                <img src={spacer} alt={""}/>
            </UniverseSpacer>
            <UniverseWrapper>
                <UniverseH1 data-aos="fade-up"> Visit Our Universe </UniverseH1>
                <UniverseH2 data-aos="fade-up"> Read now the first teaser </UniverseH2>
                <UniverseElement data-aos="fade-up">
                    <UniverseElH1> The Galaxies of Fire </UniverseElH1>
                    <UniverseElP>
                        The exponential magnification of an
                        elderly star located in sector NGC 1203,
                        gave rise to a supernova with a
                        immense amount of radiation
                        magnetic.
                    <br/>
                        The density of heat gave off was
                        something never seen before in the universe,
                        a process that would have scored forever
                        the future of the known space.

                    </UniverseElP>
                    <UniverseImageWrapper>
                        <Parallax bgImage={Image} strength={300} bgImageAlt="uiniverse" >
                            <UniverseImage1/>
                        </Parallax>
                        <UniverseThumbnail>
                            The Galaxies of Fire - 2022 - Hidden Studios
                        </UniverseThumbnail>
                    </UniverseImageWrapper>
                    <UniverseElP>
                        The shock wave produced by it caused a
                        large amount of materials, and small debris
                        asteroids, dispersed at a very high level
                        speed in multiple directions but
                        agglomerating mainly in ...
                    </UniverseElP>
                    <UniverseB1>Keep reading on</UniverseB1>
                    <UniverseButton/>
                </UniverseElement>
            </UniverseWrapper>
            <UniverseSpacerEnd>
                <img alt="spacer" src={spacer}/>
            </UniverseSpacerEnd>
        </UniverseContainer>
    )
}

export default UniversePreview;