import React, {useState,useEffect} from "react";
import HeroSection from "../components/HeroSection";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Team from "../components/Team";
/*import Roadmap from "../components/Roadmap";
import Lightpaper from "../components/Community";*/
import NFT from "../components/NFT";
import UniversePreview from "../components/UniversePreview";
import AOS from 'aos';
import $ from "jquery";



const Home = (window) => {

    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }

    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    return (
        <>
            {/*<div id="loader"/>*/}
            <Sidebar isOpen={isOpen} toggle={toggle} />
            <Navbar toggle={toggle} />
            <HeroSection />
            <NFT />
            {/*<Clans />*/}
            <UniversePreview />
            <Team />
            {/*<Roadmap />
            <Lightpaper/>*/}
            <Footer />
        </>
    )
}


export default Home