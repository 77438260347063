import styled from "styled-components";

export const LinkDispatcherContainer = styled.div`
    
    `

export const LinkDispatcherWrapper = styled.div`
    
    `

export const LinkElement = styled.div`
    
    `

export const LinkWrapper = styled.div`
    
    `

export const LinkText = styled.h4`
    
    `