import styled from "styled-components";
import {MdArrowRight,MdArrowForward} from "react-icons/md";
import BGHero from '../../images/GAUWILLDEF.jpg';
import ArrowsDown from "../../images/arrows-down.png";
// import LogoHero from "../../images/nclogoh1.svg";
import LogoHeroBig from "../../images/LogoBig.svg";
import {Link as LinkS} from 'react-scroll';

export const HeroContainer = styled.div`
    background: #0c0c0c;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    height: 1200px;
    max-height: 90vh;
    position: relative;
    z-index: 1;
    // padding-top: 80px;
    
    :before{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(180deg,
         rgba(0,0,0,0.2) 0%,
          rgba(0,0,0,0.6) 100%),
           linear-gradient(180deg,
            rgba(0,0,0,0.2) 0%,
             transparent 100%);
        z-index: 2;
    }
`

export const HeroParallaxDimensions = styled.div`
    width: 100vw;
    height: 1200px;
    max-height: 90vh;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: space-evenly;
`

export const HeroBg = styled.div`
    position:absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-size: cover;
    background-position: center;
`

export const VideoBg = styled.video`
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    background: #232a34;
`

export const HeroContent = styled.div`
    z-index: 3;
    // max-width: 1200px;
    width: 100%;
    position: absolute;
    padding: 8px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const HeroLogo = styled.div`
    min-height: 146px;
    height: 100%;
    max-height: 350px;
    width: 100%;
    min-width: 200px;
    background-image: url('${LogoHeroBig}');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin-top: 48px;
    filter: drop-shadow(2px 2px 2px rgb(0 0 0 / 0.5));
    
    @media screen and (max-width: 768px){
        width: 50%;
        margin-bottom: -40px;
    }
    
    @media screen end (max-width: 480px){
        margin-bottom: -40px;
        width: 33%;
    }
    
`

export const HeroH1 = styled.h1`
    color: #fff;
    max-width: 95%;
    width: 1000px;
    font-size: 72px;
    text-align: center;
    // font-family: 'Futura PT',futura-pt,'Montserrat';
    font-family: 'Nunito Sans',sans-serif;
    font-weight: 800;
    text-transform: uppercase;
    line-height: 64px;
    text-shadow: 2px 2px 2px #00000080;
    
    @media screen and (max-width: 768px),
           screen and (max-height: 768px){
        font-size: 40px;
        line-height: 48px;
    }
    
    @media screen and (max-width: 600px){
        font-size: 32px;
        line-height: 32px;
    }
    
    @media screen end (max-width: 480px){
        font-size: 32px;
        line-height: 32px;
    }
`

export const HeroP = styled.p`
    color: #fff;
    font-size: 24px;
    text-align: center;
    font-family: Montserrat,sans-serif;
    letter-spacing: 0.075rem;
    text-transform: uppercase;
    font-weight: 600;

    @media screen and (max-width: 768px){
        font-size: 20px;
    }
    
    @media screen end (max-width: 480px){
        font-size: 18px;
    }
`

export const HeroBtnWrapper = styled.div`
    margin-top: -24px;
    display:flex;
    flex-direction: column;
    align-items: center;
`

export const ArrowForward = styled(MdArrowForward)`
    margin-left: 8px;
    font-size: 20px;
`

export const ArrowRight = styled(MdArrowRight)`
    margin-left: 8px;
    font-size: 20px;
`

export const LineSeparation = styled.div`
    width: 1920px;
    height: 16px;
    z-index: 2;
    align-self: flex-end;
    margin: 0 -30px -16px -30px;
    flex: auto;
    overflow: hidden;
    display: flex;
    justify-content: center;
`

export const ArrowsDownContainer = styled.div`
    width: 80px;
    height: 80px;
    z-index: 2;
    // flex: auto;
    justify-content: center;
    background-image: url(${ArrowsDown});
    background-position: center;
    background-repeat: no-repeat;
`

export const ArrowsDownLink = styled(LinkS)`
    width: 80px;
    height: 80px;
    z-index: 2;
    // flex: auto;
    justify-content: center;
    background-image: url(${ArrowsDown});
    background-position: center;
    background-repeat: no-repeat;
`

export const TriangleDown = styled.div`
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 12px solid #100f15;
    position: absolute;
`