import styled from "styled-components";
import {Link as LinkR} from 'react-router-dom';
import {Link as LinkS} from 'react-scroll';
/*
import NeeveLogo from '../../images/neevelogomenu.png'
*/
import NeeveLogoWhite from '../../images/neevelogomenuwhite.png'

export const Nav = styled.nav`
    background: ${({scrollNav}) => (scrollNav ? 'rgba(0,0,0,0.8)' : 'transparent')};
    height: 80px;
    margin-top: -80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    position: sticky;
    top: 0;
    z-index: 10;
    transition: 0.8s all ease;
    

@media screen and (max-width: 960px){
    transition: 0.8s all ease;
}
`

export const NavbarContainer = styled.div`
    display: flex;
    justify-content: space-between;
    height: 80px;
    z-index: 1;
    
    width: 100%;
    padding: 0 24px;
    max-width: 1100px;
`

export const NavLogo = styled(LinkR)`
    color:  #f1f1f1;
    justify-self: pointer;
    font-size: 24px;
    display: flex;
    align-items: center;
    margin-left: 24px;
    font-weight: 600;
    text-decoration: none;
    letter-spacing: 2.4px;
    font-family:'Montserrat';
    text-transform: uppercase;
    
    opacity: 60%;
    
    &:hover{
        color: white;
        opacity: 100%;
    }
    
    @media  screen and (max-width: 600px){
        margin: 0px;
    }
`

export const MobileIcon = styled.div`
    display: none;
    
    @media screen and (max-width:768px){
        display: block;
        position: absolute;
        top:0;
        right:0;
        transform:translate(-100%,60%);
        font-size: 1.8rem;
        cursor: pointer;
        color: #fff;
    }
`

export const NeeveLogoMenu = styled.div`
    display: flex;
    width: 40px;
    height: 40px;
    background-image: url(${NeeveLogoWhite});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin: 8px;
    
    /*&.active{
    background-image: url(${NeeveLogoWhite});
    }
    
    &:hover{
    background-image: url(${NeeveLogoWhite});
    }*/
`

export const NavMenu = styled.ul`
    display: flex;
    align-items: center;
    list-style: none;
    test-align: center;
    margin-right: -22px;
    
    @media screen and (max-width:768px){
        display: none;
    }
`

export const NavItem = styled.li`
    height: 80px;
`

export const NavLang = styled.div`
    
`

export const NavLinks = styled(LinkS)`
    color: #f1f1f1;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;
    text-transform: uppercase;
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 24px;
    letter-spacing: 2.4px;
    
    opacity: 60%;
    
    &.active{
        border-bottom: 3px solid #01bf71;
        color: white;
        opacity: 100%;
    }
    
    &:hover{
        color: white;
        opacity: 100%;
    }
`

export const NavLinkr = styled(LinkR)`
    color: #f1f1f1;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;
    font-size: 0.8rem;
    
    &.active{
        border-bottom: 3px solid #01bf71;
    }
`

export const NavBtn = styled.nav`
    display: flex;
    align-items: center;
    width: 180px;
    
    @media screen and (max-width: 768px) {
        display: none;
    }
`

export const NavBtnLink = styled(LinkR)`
    border-radius: 50px;
    background: #01bf71;
    white-space: nowrap;
    padding: 10px 22px;
    color: #010606;
    font-size: 16px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    
    &:hover {
        transition: all 0.2s ease-in-out;
        background: #fff;
        color: #010606;
    }
`
;