import styled from 'styled-components';
import {MdSearch} from "react-icons/md";
import {Link as LinkR} from "react-router-dom";
import Lock from "../../images/lock.png";
import LockWhite from "../../images/lock-white.png";

export const UniverseContainer = styled.div`
    background: #111;
    width: 100%;
    height: calc(100vh - 48px);
    display:flex;
    flex-direction: column;
    overflow-y: auto;
    color: rgba(255,255,255,0.8);
`

export const UniverseWrapper = styled.div`
    display: flex;
    max-width: 1100px;
    width: 100%;
    height: 100%;
    align-content: center;
    flex-direction: column;
    /*max-width: calc(100vw - 48px);*/
    margin: 0 auto;
    overflow-y:auto;
    padding: 12px;
    align-items: center;
`

export const UniverseNavbar = styled.div`
    background: blue;
    width: 100%;
    height: 80px;
    display:flex;
`

export const UniverseHeadbar = styled.div`
    background: darkgrey;
    margin: 24px auto;
    display: flex;
    flex-direction: rows;
    width: 1100px;
    height: 42px;
    border-radius: 21px;
    background: linear-gradient(180deg, #3A3A3A 0%, #2D2D2D 100%);
    line-height: 42px;
    max-width: calc(100vw - 24px);

`

export const UniverseHeadLeft = styled.div`
    height: 100%;
    flex-grow: 1;
    padding-left: 24px;
    color: #eee;
`

export const UniverseHeadRight = styled.div`
    // width: 240px;
    border-radius: 18px;
    // background-color: #111;
    // height: 36px;
    // margin: 3px;
    // line-height: 36px;
    color: white;
    text-align: right;
    display: flex;
`

export const UniverseArticlesContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 48px;
    margin: 24px 0;
    justify-content: center;
}
`

export const UniverseArticleCard = styled.div`
    // display: flex;
    height: 300px;
    width: 300px;
    background: grey;
`

export const UniverseArticleCardBack = styled.div`
    // display: flex;
    height: 100%;
    width: 100%;
    background: grey;
    flex-direction: column;
    justify-content: flex-end;
`

export const UniverseArticleCardImg = styled.div`
    height: 300px;
    width: 300px;
    // position: absolute;
    background-position: center;
    background-size: cover;
`

export const UniverseArticleLink = styled(LinkR)`
    width: 300px;
    height: 300px;
    // position: absolute;
    // z-index: 1;
    
    // test
    text-decoration: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    transform: translateY(-100%);
    padding-bottom: 8px;
`

export const UniverseArticleLock = styled.div`
    width: 300px;
    height: 300px;
    // position: absolute;
    z-index: 3;
    background-image: url(${Lock});
    background-color: rgba(0,0,0,0.2);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 120px 120px;
    position: relative;
    margin-bottom: -300px;
    
    &:hover{
        background-image: url(${LockWhite});
    }
`

export const UniverseCaption = styled.div` */
    position: relative;
    transform: translateY(-100%);
    padding-bottom: 8px;
    width: 300px;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
`

export const UniverseArticleCardH1 = styled.h1`
    color: #f1f1f1;
    font-family: montserrat;
    text-transform: uppercase;
    font-size: 24px;
    line-height: 24px;
    z-index: 2;
    // height: 24px;
    margin: 4px 12px;
`

export const UniverseArticleCardH2 = styled.h2`
    z-index: 2;
    font-size: 16px;
    font-family: montserrat;
    color: #f1f1f1;
    height: 24px;
    
    padding-left: 12px;
`

export const UniverseArticleCardData = styled.p`
    z-index: 2;
    font-size: 12px;
    font-weight: bold;
    font-family: montserrat;
    color: #f1f1f1;
    height: 16px;
    padding-left: 12px;
`

export const UniverseH1 = styled.h1`
    color: white;
    font-size: 32px;
    font-family: 'Lato';
`

export const UniverseH2 = styled.h2`
    color: inherit;
    font-size: 20px;
    font-family: 'Montserrat';
    font-weight: 500;
`

export const UniverseP = styled.p`
    color: white;
    font-size: 16px;
    font-family: 'Lato';
`

export const SearchIcon = styled(MdSearch)`
    margin-left: 8px;
    font-size: 20px;
`