import styled from "styled-components";
// import universeicon from "../../images/universe-icon-black.png"
import universeiconwhite from "../../images/universewhite.png"
import {Link as LinkR} from 'react-router-dom';

export const ButtonContainer = styled.div`
    min-width: 128px;
    max-width: 200px;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-content: center;
    background: linear-gradient(#00B3FA,#0059D2);
    color: #ffffff;
    border-radius: 8px;
    align-items: center;
    cursor: pointer;
    
    &:hover{
        color: #010101;
    }
`

export const ButtonImage = styled.div`
    width: 24px;
    height: 24px;
    background-image: url(${universeiconwhite});
    background-size: contain;
    margin: 8px;
    background-position: center;
    background-repeat: no-repeat;
`

export const ButtonText = styled.div`
    line-height: 40px;
    text-align: center;
    font-family: Montserrat;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.075rem;
    margin-right: 8px;
`

export const LinkUniverse = styled(LinkR)`
    position: absolute;
    width: 140px;
    height: 40px;
`