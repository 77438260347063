import React, {useEffect, useState} from "react";
import {
    NftContainer,
    NftH1,
    NftTextContainer,
    NftH2,
    NftHeader,
    NftWrapper,
    NftP,
    NftFilter
} from "./NftElements";
import {ArrowsDownContainer,ArrowsDownLink} from "../HeroSection/HeroElements";
import portal from "../../images/portal.jpg";
import portallow from "../../images/portal-low.jpg";

const NFT = () => {

    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    return(
        <NftContainer id="nft" style={{backgroundImage: ( width >= 768 ? `url(${portal})` : `url(${portallow})`)}}>

            <NftFilter>
                <NftWrapper>
                    <NftHeader>
                        <NftH1 data-aos="fade-up">Narrative NFT</NftH1>

                    </NftHeader>
                    <NftTextContainer  data-aos="fade-up">
                        <NftP>
                            Are you ready to start this new adventure and pushing yourself beyond the limits of the universe?
                        </NftP>

                            <NftH2 data-aos="fade-up">Contribute to a dynamic history</NftH2>

                        <NftP>
                            Join Neeve Chronicles world
                            <br/>
                            and change the fate of destiny.
                        </NftP>
                    </NftTextContainer>
                    <ArrowsDownLink data-aos="fade-up"  to="universe" smooth={true} duration={500} spy={true}
                                    offset={-80}/>
                </NftWrapper>

            </NftFilter>
        </NftContainer>
        )
}

export default NFT;