import styled from 'styled-components';

export const UniverseContainer = styled.div`
    min-height: calc(100vh - 80px);
    width: 100%;
    flex: auto;
    background: linear-gradient(315deg,#40525c,#22263b);
    
    @media screen and (max-width: 768px){
        // height: 1100px;
        border-top: 1px solid #dece84;
        border-bottom:  1px solid #dece84;
    }
    
    @media screen and (max-width: 480px){
        // height: 1300px;
    }
`

export const UniverseWrapper = styled.div`
    max-width: 1100px;
    flex: 0 auto;
    margin: 0 auto;
    padding: 64px;
    flex-direction: column;
    text-align: center;
    color: white;
    
    @media  screen and (max-width: 600px){
       padding: 60px 16px;
    }
`

export const UniverseSpacer = styled.div`
    /*width: 1920px;*/
    height: 49px;
    z-index: 2;
    align-self: flex-end;
    /*margin: 0 -30px -16px -30px;*/
    flex: auto;
    overflow: hidden;
    display: flex;
    justify-content: center;
    
    
  @media screen and (max-width: 768px){
    height: 24px;    
    margin-top: -1px;
  }
`

export const UniverseSpacerEnd = styled.div`
    /*width: 1920px;*/
    height: 49px;
    z-index: 2;
    align-self: flex-end;
    /*margin: 0 -30px -16px -30px;*/
    flex: auto;
    overflow: hidden;
    display: flex;
    justify-content: center;
    transform: rotateX(180deg);
    
    @media screen and (max-width: 768px){
    height: 24px;
    margin-bottom: -1px;
  }
`

export const UniverseH1 = styled.h1`
    text-transform: uppercase;
    font-family: 'Montserrat';
    color: #DECE84;
    font-size: 64px;
    line-height: 52px;
    
    @media  screen and (max-width: 600px){
        font-size: 32px;
        line-height: 26px;
    }
`

export const UniverseH2 = styled.h2`
    font-family: 'Montserrat';
    letter-spacing: 0.125em;
    text-transform: uppercase;
    
    @media  screen and (max-width: 600px){
        
        font-size: 20px;
        line-height: 20px;
    }
    
`

export const UniverseElement = styled.div`
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
`

export const UniverseElH1 = styled.h1`
    margin: 64px 0 16px;
    font-family: 'Montserrat';
    
`

export const UniverseElP = styled.p`
    
    font-family: 'Lato';
    color: rgba(255,255,255,0.8);
    line-height: 28px;
    font-size: 20px;
    font-weight: regular;
    text-align: justify;
    
`

export const UniverseB1 = styled.p`
    font-family: 'Montserrat';
    margin-top: 40px;
    padding: 12px;
`

export const UniverseImage1 = styled.div`
    width: 100%;
    height: 360px;
`

export const UniverseImageWrapper = styled.div`
    // width: calc(100% - 48px);
    width: 100%;
    margin: 24px;
`

export const UniverseThumbnail = styled.div`
    width: 100%;
    height: 32px;
    text-align: right;
    font-family: Montserrat;
    font-size: 14px;
    color: rgba(255,255,255,0.8)
`