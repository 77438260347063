import React from "react";
import {SidebarContainer,Icon,CloseIcon,SidebarWrapper,SidebarLink,SidebarMenu} from "./SidebarElements";

const Sidebar = ({isOpen, toggle}) => {
    return(
        <SidebarContainer isOpen={isOpen} onClick={toggle}>
            <Icon  onClick={toggle}>
                <CloseIcon />
            </Icon>
            <SidebarWrapper>
                <SidebarMenu>
                    <SidebarLink to="nft"  onClick={toggle}>
                        NFT
                    </SidebarLink>
                    <SidebarLink to="universe"  onClick={toggle}>
                        Universe
                    </SidebarLink>
                   {/* <SidebarLink to="strengths"  onClick={toggle}>
                        Strengths
                    </SidebarLink>
                    <SidebarLink to="roadmap"  onClick={toggle}>
                        Roadmap
                    </SidebarLink>*/}
                    <SidebarLink to="team"  onClick={toggle}>
                        Team
                    </SidebarLink>
                    {/*<SidebarLink to="lightpaper"  onClick={toggle}>
                        Lightpaper
                    </SidebarLink>*/}
                </SidebarMenu>
                {/*<SideBtnWrap>*/}
                {/*    <SidebarRoute to="/universe">*/}
                {/*        Universe*/}
                {/*    </SidebarRoute>*/}
                {/*</SideBtnWrap>*/}
            </SidebarWrapper>
        </SidebarContainer>
    )
}

export default Sidebar;