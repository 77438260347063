import styled from 'styled-components';
import teamlogo from '../../images/hiddenstudioslogo.png';

export const TeamContainer = styled.div`
    padding: 60px 0;
    min-height: 800px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(135deg,#4B6CB7,#182848);
    color: rgba(255,255,255,0.8);
   
`

export const TeamWrapper = styled.div`
    max-width: 1100px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
`

export const TeamSection = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    align-items: center;
    border-top: 1px solid #c9c9c9;
    color: rgba(255,255,255,0.8);
`

export const SectionNames = styled.div`
    // padding: 8px;
    width: 100%;
`

export const SectionH1 = styled.h1`
    font-size: 24px;
    line-height: 48px;
    font-family: Montserrat;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    text-align: center;
    color: inherit;
    margin-top: 16px;
`

export const TeamCard = styled.div`
    // background: #fff;
    // display: flex;
    // flex-direction: column;
    // justify-content: flex-start;
    // align-items: center;
    // border-radius: 10px;
    // max-height: 340px;
    // padding: 30px;
    // box-shadow: 0 1px 3px rgba(0,0,0,0.2);
    // transition: all 0.2s ease-in-out;
    //
    // &:hover{
    //     transform: scale(1.02);
    //     transition: all 0.2s ease-in-out;
    //     cursor: pointer;
    // }
    
    // border-top: 1px dashed #f1f1f1;
    // margin-bottom: 8px;
    
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    
    &:last-of-type{
        margin-bottom: 12px;
    }
    
    @media  screen and (max-width: 600px){
        height: 60px;
    }
`

export const TeamLogo = styled.div`
    width: 100%;
    max-width: 480px;
    height: 180px;
    background-image: url(${teamlogo});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    
    
  @media screen and (max-width: 768px){
        width: calc(100% - 96px);
  }
`

export const TeamIcon = styled.img`
    height: 160px;
    width: 160px;
    margin-bottom: 10px;  
`

export const TeamH1 = styled.h1`
    font-size: 40px;
    font-family: Montserrat;
    text-transform: uppercase;
    color: inherit;
    margin: -50px 0px 12px 0px;
    letter-spacing: 4px;
    @media screen and (max-width: 480px){
        font-size: 2rem;
    }
`

export const TeamH2 = styled.h2`
    font-size: 1rem;
    font-family: Lato;
    padding-left: 40px;
    // line-height: 60px;
    text-transform: uppercase;
    
    @media  screen and (max-width: 600px){
        padding-left: 20px;
    }
`

export const TeamP = styled.p`
    font-family: Lato;
    font-size: 1rem;
    align-self: center;
    padding-right: 40px;
    padding-left: 40px;
    text-align: right;
    
    @media  screen and (max-width: 600px){
        padding-left: 20px;
        padding-right: 20px;
    }
`