import React from "react";

import {TeamContainer,TeamH2,TeamH1,TeamLogo,SectionNames,TeamSection,SectionH1,TeamP,TeamWrapper,TeamCard} from "./TeamElements";

const Team = () => {
    return(
        <TeamContainer id="team">
            <TeamLogo/>
            <TeamH1 data-aos="fade-up">Team</TeamH1>
            <TeamWrapper>
                <TeamSection>
                    <SectionH1 data-aos="fade-up">Leadership / Cofounders</SectionH1>
                    <SectionNames>
                        <TeamCard data-aos="fade-up">
                            <TeamH2>Domenico Sellaro</TeamH2>
                            <TeamP>Founder & Art Director</TeamP>
                        </TeamCard>
                        <TeamCard data-aos="fade-up">
                            <TeamH2>Matteo Policastrese</TeamH2>
                            <TeamP>Founder & Narrative Designer</TeamP>
                        </TeamCard>
                    </SectionNames>
                </TeamSection>
                <TeamSection>
                    <SectionH1 data-aos="fade-up">3D Department</SectionH1>
                        <SectionNames>
                        <TeamCard data-aos="fade-up">
                            <TeamH2>Eugenio Panizza</TeamH2>
                            <TeamP>Unreal Engine & Environment Artist</TeamP>
                        </TeamCard>
                        <TeamCard data-aos="fade-up">
                            <TeamH2>Emanuele Costanzo</TeamH2>
                            <TeamP>3D Artist</TeamP>
                        </TeamCard>
                            <TeamCard data-aos="fade-up">
                                <TeamH2>Rain Robert Philips</TeamH2>
                                <TeamP>Rigger</TeamP>
                            </TeamCard>
                            <TeamCard data-aos="fade-up">
                                <TeamH2>Roberto Godeassi</TeamH2>
                                <TeamP>3D Animator</TeamP>
                            </TeamCard>
                            <TeamCard data-aos="fade-up">
                                <TeamH2>Pasquale Lumare</TeamH2>
                                <TeamP>3D Animator</TeamP>
                            </TeamCard>
                        </SectionNames>
                    </TeamSection>
                    <TeamSection>
                        <SectionH1 data-aos="fade-up">Social Media & Management</SectionH1>
                        <SectionNames>
                        <TeamCard data-aos="fade-up">
                            <TeamH2>Marco Sestito</TeamH2>
                            <TeamP>Social Media Manager</TeamP>
                        </TeamCard>
                    </SectionNames>
                </TeamSection>
                <TeamSection>
                    <SectionH1 data-aos="fade-up">Software Development</SectionH1>
                    <SectionNames>
                    <TeamCard data-aos="fade-up">
                        <TeamH2>Paolo Ripani</TeamH2>
                        <TeamP>Web Designer & Web Developer</TeamP>
                    </TeamCard>
                    <TeamCard data-aos="fade-up">
                        <TeamH2>Davide Salvadore</TeamH2>
                        <TeamP>Backend & Blockchain Integration</TeamP>
                    </TeamCard>
                    </SectionNames>
                </TeamSection>
                <TeamSection>
                    <SectionH1 data-aos="fade-up">Music</SectionH1>
                    <SectionNames>
                    <TeamCard data-aos="fade-up">
                        <TeamH2>Salvatore Palermo</TeamH2>
                        <TeamP>Sound Designer & Composer</TeamP>
                    </TeamCard>
                    </SectionNames>
                </TeamSection>
            </TeamWrapper>
        </TeamContainer>
    )
}

export default Team;