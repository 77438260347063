import React, { Component} from "react";
import Switch from "react-switch";
import "./Switchdark.css";
import {SwitchDarkContainer,IconStatus} from "./SwitchdarkElements";
import $ from "jquery";
// import darkicon from "../../images/night-mode-white.png";
// import lighticon from "../../images/sun-white.png"

class SwitchDark extends Component {

    constructor(){
        super();
        this.state = {checked: false};
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(checked) {
        this.setState({ checked });
        console.log($("#UniverseContainer"));
        $("#UniverseContainer").toggleClass("Light");
        $("#toggleDark").toggleClass("light");
    }

    render(){
        return(
            <SwitchDarkContainer>
                <label className="labelDarkSwitch">
                    <Switch id="switchDarkButton" onChange={this.handleChange} checked={this.state.checked}
                            checkedIcon={false} uncheckedIcon={false}/>
                </label>
                <IconStatus id="toggleDark" />
            </SwitchDarkContainer>
        );
    }
}


//
// const SwitchDark = ({ isOn }) => {
//     const [isDark, setDark] = useState(false);
//
//     const handleToggleDark = () => {
//         setDark(!isDark)
//     }
//
//     return(
//         <>
//             <div className="switchDarkContainer">
//                 <input className="react-switch-checkbox" id={`react-switch-new`} type="checkbox"
//                        checked={isOn} onChange={handleToggleDark}
//                 />
//                 <label className="react-switch-label" htmlFor={`react-switch-new`}
//                        style={{ background: isOn && '#06D6A0' }}
//                 >
//                     <span className={`react-switch-button`} />
//                 </label>
//                 <div className={`logoDark ${isDark ? "rightLight" : "leftDark"}`}></div>
//             </div>
//
//         </>
//
//     )
// }
//

export default SwitchDark;

