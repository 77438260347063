import React, {useState, useEffect} from "react";
import {FaBars} from 'react-icons/fa';
import {IconContext} from 'react-icons/lib';
import {Nav,NavLogo,NavbarContainer,NavItem,NavLinks,NeeveLogoMenu,NavMenu,MobileIcon,NavBtn} from "./NavbarElements";
import {animateScroll as scroll} from "react-scroll";
// import UniverseButton from "../UniverseButton";

const Navbar = ({toggle}) => {
    const [scrollNav, setScrollNav ] = useState(false)

    const changeNav = () => {
        if(window.scrollY >= 80 ) {
            setScrollNav(true);
        } else {
            setScrollNav(false);
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', changeNav)
    })

    const toggleHome = () => {
        scroll.scrollToTop();
    }

    return (
        <>
            <IconContext.Provider value={{color: '#fff'}}>
            <Nav scrollNav={scrollNav}>
                <NavbarContainer>
                    <NavLogo to="/" onClick={toggleHome}>
                        <NeeveLogoMenu/>
                        Home
                    </NavLogo>
                    <MobileIcon onClick={toggle}>
                        <FaBars />
                    </MobileIcon>
                    <NavMenu>
                        {/*<NavItem>
                            <NavLinks to="nft" smooth={true} duration={500} spy={true}
                                      offset={-80} activeClass="active"
                            >NFT</NavLinks>
                        </NavItem>*/}
                        {/*<NavItem>
                            <NavLinks to="clans" smooth={true} duration={500} spy={true}
                                      offset={-80} activeClass="active"
                            >Clans</NavLinks>
                        </NavItem>*/}
                        <NavItem>
                            <NavLinks to="nft" smooth={true} duration={500} spy={true}
                                      offset={-80} activeClass="active"
                            >Nft</NavLinks>
                        </NavItem>
                        {/*<NavItem>
                            <NavLinks to="roadmap" smooth={true} duration={500} spy={true}
                                      offset={-80} activeClass="active"
                            >Roadmap</NavLinks>
                        </NavItem>*/}
                        <NavItem>
                            <NavLinks to="universe" smooth={true} duration={500} spy={true}
                                      offset={-80} activeClass="active"
                            >Story</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks to="team" smooth={true} duration={500} spy={true}
                                      offset={-80} activeClass="active"
                            >Team</NavLinks>
                        </NavItem>
                    </NavMenu>
                    <NavBtn>
                        {/*<UniverseButton/>*/}
                    </NavBtn>
                </NavbarContainer>
            </Nav>
            </IconContext.Provider>
        </>
    )
}

export default Navbar