import styled from "styled-components";
import {Link as LinkR} from 'react-router-dom';
// import {Link as LinkS} from 'react-scroll';
import universeiconwhite from "../../images/universewhite.png"

export const UniverseNavbarWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    height: 100%;
    z-index: 1;
    padding: 0 24px;
    width: 1100px;
    margin: 0 auto;
    
    @media  screen and (max-width: 600px){
        
        padding: 0px;
    }
`

export const UniverseNavbarContainer = styled.div`
    display: flex;
    height: 48px;
    z-index: 1;
    width: 100%;
    background: linear-gradient(180deg, #00B3FA, #0059D2);
    color: rgba(255,255,255,0.8);
`

export const NavMenuLeft = styled(LinkR)`
    display: flex;
    height: 48px;
    float: left;
    padding: 0 24px;
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 24px;
    line-height: 48px;
    cursor: pointer;
    text-decoration: none;
    color: rgba(255,255,255,0.8);
`

export const NavMenuRight = styled(LinkR)`
    display: flex;
    height: 48px;
    float: left;
    padding: 0 24px;
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 24px;
    line-height: 48px;
    text-decoration: none;
    color: white;
    align-content: center;
    align-items: center;
    opacity: 0.8;
    
    &:hover{
       opacity: 1;
    }
`

export const NavLang = styled.div`
    
`

export const NavIcon = styled.div`
    background-image: url(${universeiconwhite});
    background-size: contain;
    margin: 8px;
    background-position: center;
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
`