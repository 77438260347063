import React from "react";
import {ArticleWrapper,ArticleH1,ArticleContainer,ArticleH2,ArticleImg,ArticleP,ArticleDate,ArticleFooter} from './UniversearticleElement';
import {
    // UniverseArticleCard, UniverseArticleCardBack, UniverseArticleCardData, UniverseArticleCardH1, UniverseArticleCardH2,
    // UniverseArticlesContainer,
    UniverseContainer,
    // UniverseH2,
    UniverseHeadbar,
    UniverseHeadLeft,
    UniverseHeadRight,
    UniverseWrapper
} from "../UniverseHomepage/UniverseElements";
import UniverseNavbar from "../UniverseNavbar";
import "../UniverseHomepage/universehomepage.css";
import SwitchDark from "../SwitchDark";
/*import Data from './Data';*/

const UniverseArticle = () => {
    // const isDark = 1;

    return(
        <>
            <UniverseNavbar></UniverseNavbar>
            <UniverseContainer id="UniverseContainer">
                <UniverseHeadbar>
                    <UniverseHeadLeft>Universe BETA</UniverseHeadLeft>
                    <UniverseHeadRight>
                        {/*<SearchIcon className={"searchIconUniverse"}></SearchIcon>
                        <input className={"searchBarUniverse"} type={"text"} placeholder={"search"} />*/}
                        <SwitchDark />
                    </UniverseHeadRight>
                </UniverseHeadbar>
                <UniverseWrapper>

                    <ArticleContainer>
                        <ArticleWrapper>
                                <ArticleH1>
                                    The Galaxies of Fire
                                </ArticleH1>
                                <ArticleH2>
                                    Deep into our Universe
                                </ArticleH2>
                            <ArticleP>
                                The exponential magnification of an
                                elderly star located in sector NGC 1203,
                                gave rise to a supernova with a
                                immense amount of radiation
                                magnetic.
                            </ArticleP>
                            <ArticleP>
                                The density of heat gave off was
                                something never seen before in the universe,
                                a process that would have scored forever
                                the future of the known space.


                            </ArticleP>
                            <ArticleImg></ArticleImg>
                            <ArticleP>
                                The shock wave produced by it caused a
                                large amount of materials, and small debris
                                asteroids, dispersed at a very high level
                                speed in multiple directions but
                                agglomerating mainly in 5 points
                                of different space by creating
                                nebulae.
                            </ArticleP>
                            <ArticleP>
                                The dust and asteroids present in them,
                                colliding and joining together.
                                They made way for more and more bodies
                                large and consistent: new stars.
                            </ArticleP>
                            <ArticleP>
                                These new 5 star clusters are known today as
                                "The 5 Galaxies of Fire"</ArticleP>
                            <ArticleDate>Neeve Chronicles - 14/06/2022</ArticleDate>
                        </ArticleWrapper>
                        <ArticleFooter>

                        </ArticleFooter>
                    </ArticleContainer>
                </UniverseWrapper>
            </UniverseContainer>
        </>
    )
}

export default UniverseArticle